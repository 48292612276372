export const getConfigurationPerHostname = hostname => {
    switch (hostname) {
        case 'dataviz.harmonie-mutuelle.fr':
            return {
                apiHost: 'https://rec.api.harmonie-mutuelle.fr/services/dataviz-entreprise-api',
                apiKey: '3567c394po814a7699k1ms0accfe2906',
                loginUrl:
                    'https://rec.harmonie-mutuelle.fr',
            };
        case 'localhost':
            return {
                apiHost:
                    process.env.REACT_APP_API_URL ||
                    'http://localhost:8080/dataviz-entreprise-api',
                apiKey: 'development',
            };
        default:
            return {
                apiHost: 'https://rec.api.harmonie-mutuelle.fr/services/dataviz-entreprise-api',
                apiKey: '3567c394po814a7699k1ms0accfe2906',
                loginUrl:
                    'https://rec.harmonie-mutuelle.fr',
            };
    }
};
